import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import Container from "../components/container"
import Block from '../components/donate-block'
import donateBg from '../../static/donation.jpg'
import wechatPay from '../../static/wechat-qrcode.jpg'
import { FaShareAlt, FaHeart } from "react-icons/fa"

const ImageSection = ({text, src}) => (
  <div className="column has-text-centered">
    <h4>{text}</h4>
    <img
      alt={text}
      src={src}
      css={{
        height: "80%"
      }}
    />
  </div>
);

class DonatePage extends React.Component {

  render() {
    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet>
            <title>捐助</title>
          </Helmet>
          <div className="section is-hero is-medium is-primary" css={{background: `url(${donateBg})`}}>
            <Container>
              <div className="section-header">
                <h1 className="title is-1 has-text-light	is-spaced">
                  帮助他人，就是帮助自己
                </h1>
              </div>
            </Container>
          </div>

          <section className="section">
            <Block Icon={FaShareAlt} iconColor="has-text-link">
              <h2>帮助社区</h2>
              <p>
                睡吧需要多种多样的人来帮助睡吧构建完善的内容，提供必要的技术支持。你可以通过下面的途径帮助失眠人群：
              </p>
              <ul>
                <li>在豆瓣小组回复失眠者的提问或者发表个人经验</li>
                <li>编辑公众号文章</li>
                <li>如果你有对技术感兴趣，可以帮助一起建立睡吧网站和应用</li>
              </ul>
            </Block>

            <Block Icon={FaHeart} iconColor="has-text-primary">
              <h2>捐助睡吧</h2>
              <p>
                目前睡吧日常的所有费用都是个人承担，不论是内容的更新还是服务的建立都无法顺利开展，这也严重限制了睡吧知识的传播。你可以通过支付宝和微信来捐助睡吧。
              </p>
              <div className="columns">
                <ImageSection text="微信捐助" src={wechatPay} />
              </div>
            </Block>
          </section>
        </div>
      </Layout>
    );
  }
}

export default DonatePage
