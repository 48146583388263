import React from "react"

export default ({ children, Icon, iconColor }) => (
  <div className="columns is-4">
    <div className="column is-2 has-text-centered">
      <Icon className={`${iconColor} is-size-1`} />
    </div>
    <div className="column">
      <div className="content">
        {children}
      </div>
    </div>
  </div>
)
